import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../../components/layout/layout';
import { ArticleCard } from '../../components/cards/article-card';
import { PageHeading } from '../../components/layout/page-heading';
import { PageSubHeading } from '../../components/layout/page-subheading';
import { IllustrationCard } from '../../components/cards/illustration-card';
import { PageDescription } from '../../components/layout/page-description';

const PrimaryButton = ({ text, link }) => (
  <div>
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="w-full flex items-center justify-center px-8 py-3 sm:p-4 border-2 border-transparent text-base font-semibold rounded-md bg-primary hover:bg-secondary shadow-md"
    >
      {text}
    </a>
  </div>
);

const SecondaryLink = ({ text, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noreferrer"
    className="w-full flex items-center justify-center px-8 py-3 sm:p-4 border-2 border-primary hover:border-secondary text-base font-semibold rounded-md bg-white shadow-md"
  >
    {text}
  </a>
);

const Footprints = ({ data }) => {
  const {
    title,
    subtitle,
    primaryButton,
    secondaryButton,
    articles = [],
    addresses = [],
    illustrations = [],
    creativePieces = [],
  } = data.allContentfulFootprints.edges[0].node;
  
  return (
    <Layout
      title={title}
      styles=""
      description="'Footprints' is an annual magazine published by the Lankarama Buddhist Youth Group (LBYG), distributed with the aim to edify readers on a variety of Buddhist concepts. Each magazine contains valuable contributions from members of both the LBYG and the wider community, broadening our collective knowledge base year upon year."
      image="/images/Footprints.png"
    >
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-0">
        <div className="max-w-2xl mx-auto py-8 lg:max-w-none">
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <PageHeading title={title} styles={'sm:mb-0 mb-4'} />
            <div className="flex">
              <div>
                <PrimaryButton
                  text={primaryButton.linkText}
                  link={primaryButton.linkUrl}
                />
              </div>
              <div className="ml-3">
                <SecondaryLink
                  text={secondaryButton.linkText}
                  link={secondaryButton.linkUrl}
                />
              </div>
            </div>
          </div>
          <PageDescription description={subtitle.subtitle} />

          {/* Addresses */}
          <div>
            <PageSubHeading title="Addresses" styles={'mt-8'} />
            <div className="mt-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-y-6 md:gap-4">
              {addresses?.map((address) => (
                <Link
                  to={address.slug}
                  key={address.id}
                  className="group col-span-2 md:col-span-1"
                >
                  <ArticleCard article={address} type="address" />
                </Link>
              ))}
            </div>
          </div>

          {/* Illustrations */}
          <div>
            <PageSubHeading title="Illustrations" styles={'mt-8'} />
            <div className="mt-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-y-6 md:gap-4">
              {illustrations?.map((illustration) => (
                <Link
                  to={illustration.slug}
                  key={illustration.id}
                  className={'col-span-2 md:col-span-1'}
                >
                  <IllustrationCard
                    image={illustration.creativePiece.fixed.src}
                    title={illustration.title}
                    author={illustration.author}
                    date={illustration.submissionDate}
                  />
                </Link>
              ))}
            </div>
          </div>

          {/* Articles */}
          <div>
            <PageSubHeading title="Articles" styles={'mt-8'} />
            <div className="mt-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-y-6 md:gap-4">
              {articles?.map((article) => (
                <Link
                  to={article.slug}
                  key={article.id}
                  className="group col-span-2 md:col-span-1"
                >
                  <ArticleCard article={article} type="article" />
                </Link>
              ))}
            </div>
          </div>

          {/* Creative */}
          <div>
            <PageSubHeading title="Creative Pieces" styles={'mt-8'} />
            <div className="mt-6 grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-y-6 md:gap-4">
              {creativePieces?.map((creativePiece) => (
                <Link
                  to={creativePiece.slug}
                  key={creativePiece.id}
                  className="group col-span-2 md:col-span-1"
                >
                  <ArticleCard article={creativePiece} type="creative" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Footprints;

export const query = graphql`
  query {
    allContentfulFootprints(
      limit: 1
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          title
          subtitle {
            subtitle
          }
          primaryButton {
            linkText
            linkUrl
          }
          secondaryButton {
            linkText
            linkUrl
          }
          articles {
            id
            title
            slug
            submissionDate(formatString: "Do MMMM, YYYY")
            author {
              ...Author
            }
          }
          addresses {
            id
            title
            slug
            submissionDate(formatString: "Do MMMM, YYYY")
            author {
              ...Author
            }
          }
          illustrations {
            id
            title
            slug
            submissionDate(formatString: "Do MMMM, YYYY")
            frontCover
            description {
              description
            }
            creativePiece {
              fixed(width: 700) {
                width
                height
                src
                srcSet
              }
            }
            author {
              ...Author
            }
          }
          creativePieces {
            ... on ContentfulPoem {
              id
              title
              slug
              submissionDate(formatString: "Do MMMM, YYYY")
              author {
                ...Author
              }
            }
            ... on ContentfulCreativePiece {
              id
              title
              slug
              submissionDate(formatString: "Do MMMM, YYYY")
              author {
                ...Author
              }
            }
          }
        }
      }
    }
  }
  fragment Author on ContentfulAuthor {
    name
    description {
      description
    }
    displayPicture {
      fixed(width: 400) {
        width
        height
        src
        srcSet
      }
    }
  }
`;
