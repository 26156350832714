import React from "react";

const Tag = ({ title }) => {
  let bgColor = "bg-primaryPastel";

  if (title === "creative") {
    bgColor = "bg-pinkPastel";
  } else if (title === "address") {
    bgColor = "bg-bluePastel";
  } else if (title === "event") {
    bgColor = "bg-purplePastel";
  }

  return (
    <span
      className={`px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${bgColor} text-white`}
    >
      {title}
    </span>
  );
};

export default Tag;
