import React from "react";

export const IllustrationCard = ({ image, title, author, date }) => {
  return (
    <div
      className={`flex flex-col h-full rounded-lg shadow-md transform transition duration-200 md:hover:shadow-xl`}
    >
      <img
        className="w-full h-96 rounded-t-lg object-cover object-center overflow-hidden"
        alt={`${title}`}
        src={image}
      />
      <div className={`flex flex-row flex-grow p-4 bg-greenLight rounded-b-lg`}>
        <div className="flex flex-col self-center flex-grow">
          <h2 className="text-gray-800 font-heading text-2xl font-semibold">
            {title}
          </h2>
          <p className="text-gray-500 font-semibold mt-2">{author.name}</p>
          <p className="text-sm font-medium text-gray-500 mt-2">{date}</p>
        </div>
        <img
          className="w-16 h-16 self-center justify-self-end object-cover rounded-full"
          alt={`${author.name}`}
          src={author.displayPicture.fixed.src}
        />
      </div>
    </div>
  );
};
