import React from "react";
import Tag from "../tag";

export const ArticleCard = ({ article, type = "article" }) => {
  let borderColor = "border-primaryPastel bg-primaryLight";
  if (type === "creative") {
    borderColor = "border-pinkPastel bg-pinkLight";
  } else if (type === "address") {
    borderColor = "border-bluePastel bg-blueVeryLight";
  } else if (type === "event") {
    borderColor = "border-purplePastel bg-purpleLight";
  }

  return (
    <div
      className={`flex flex-col h-full p-4 rounded-lg ${borderColor} shadow-md border-t-8 transform transition duration-200 md:hover:shadow-xl`}
    >
      <div>
        <h2 className="text-gray-800 font-heading text-2xl font-semibold">
          {article.title}
        </h2>
      </div>
      <div className="mt-2 flex-grow">
        <p className="text-gray-500 font-semibold mt-2">
          {article.author.name}
        </p>
        <p className="text-sm font-medium text-gray-500 mt-2">
          {article.submissionDate}
        </p>
      </div>
      <div className="flex mt-4">
        <div className="flex-grow self-center">
          <Tag title={type} />
        </div>
        <div className="w-16 h-16 self-end overflow-hidden">
          <img
            className="w-full h-full object-center object-cover rounded-full drop-shadow-md"
            alt={`${article.author.name}`}
            src={article.author.displayPicture.fixed.src}
          />
        </div>
      </div>
    </div>
  );
};
